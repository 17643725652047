import Head from 'next/head';

import { PageLoader } from '@/components';

export default function NotFount() {
  return (
    <>
      <Head>
        <title>Uh no - Qlub Pay</title>
      </Head>
      <PageLoader progress={0} />
    </>
  );
}
